<template>
  <section>
    <v-avatar size="100%" v-if="!error">
      <v-img aspect-ratio="1"
             @error="imageError"
            :src="this.imageUrl"
            :alt="this.firstName + ' ' + this.lastName"/>
    </v-avatar>
    <v-avatar color="primary" v-if="error" class="default">
      <div class="initials">
        <span class="white--text headline">{{ this.firstName.substr(0, 1) + ' ' + this.lastName.substr(0, 1) }}</span>
      </div>
    </v-avatar>
  </section>
</template>

<script>
/**
   * Image loading order priority:
   * 1. src property
   * 2. Gravatar
   * 3. generated initials
   *
   * If src property is empty then we try to get a Gravatar image.
   * If the Gravatar image fails to load we generate an avatar with the user's initials.
   */
import md5 from 'js-md5'
export default {
	name: 'UserAvatar',
	computed: {
		gravatarUrl() {
			return `https://www.gravatar.com/avatar/${md5(this.email)}.jpg?size=120`
		}
	},
	created() {
		if (this.pictureUrl === '' || this.pictureUrl === null) {
			this.imageUrl = this.gravatarUrl;
			console.log('UserAvatar: Trying Gravatar')
		} else {
			this.imageUrl = this.pictureUrl;
			console.log('UserAvatar: Trying property')
		}
	},
	data() {
		return {
			error: false,
			imageUrl: '',
		}
	},
	methods:{
		imageError() {
			console.log('UserAvatar: Could not load image')
			this.error = true
		}
	},
	props: {
		pictureUrl: {default: '', type: [String]},
		email: String,
		firstName: String,
		lastName: String
	}
}
</script>

<style scoped lang="scss">

  // add a gold border around the user provide avatar
  .v-avatar {
    border: 4px solid $ba-color-accent-gold;
  }

  // vertically and horizontally position the avatar initials text inside the avatar
  .initials {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    margin: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    span {
      font-size: 30px !important;
    }
  }

  // override default v-avatar properties to make avatar responsive and square (circle)
  .v-avatar.default {
    border: none;
    height: auto !important;
    min-width: 0 !important;
    min-height: 0 !important;
    padding-top: 100% !important;
    position: relative;
    width: 100% !important;
  }

</style>