
import { Component, Mixins } from "vue-property-decorator";
import { VuetifyMixin } from "../mixins/VuetifyMixin";
import { MyCoachMixin } from "../mixins";
import { MyTeamsMixin } from "../mixins";
import Page from "./Page.vue";
import CoachProfileCard from "@/components/profile/CoachProfileCard.vue";
import TeamManagement from "@/components/teams/TeamManagement.vue";

@Component({
	components: {
		CoachProfileCard,
		TeamManagement,
		Page,
	}
})
export default class CoachDashboard extends Mixins(
	MyCoachMixin,
	MyTeamsMixin,
	VuetifyMixin
) {
	reloadMyCoach = true;
	reloadMyTeams = true;
}
