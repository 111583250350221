<template>
  <img v-if="countryCode"
       :alt="`${countryLabel} Flag`"
       class="country-flag"
       :src="`https://www.countryflags.io/${countryCode}/${finish}/${size}.png`"/>
</template>

<script>
export default {
	name: 'CountryFlag',
	computed: {
		countryLabel() {
			return this.$store.getters['country/getLabel'](this.countryCode)
		}
	},
	created() {
		if (this.shiny && this.flat) {
			this.finish = 'shiny'
		} else if (this.flat) {
			this.finish = 'flat'
		}
	},
	data() {
		return {
			finish: 'shiny'
		}
	},
	props: {
		countryCode: String,
		size: {
			type: String,
			default: '24',
			validator: value => {
				//     throw new Error(`Invalid prop "size". Possible sizes are: ${sizes.join(', ')}`)
				return ['16', '24', '32', '48', '64'].indexOf(value) !== -1
			}
		},
		shiny: {type: Boolean, default: null},
		flat: {type: Boolean, default: null},
	},
}
</script>

<style scoped lang="scss">
  img {
    vertical-align: text-top;
  }
</style>