<template>
  <section>
    <v-row>
      <v-col cols="11" >
      </v-col>
      <v-col class="pa-0 pr-3 ma-0 text-right">
        <v-icon v-if="completed" @click="$emit('removeItem')" color="#81BAED">cancel</v-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="pb-0 pt-0 mb-0">
        <v-text-field
          :rules="rulesRequired"
          outlined
          label="Title"
          :value="value.title"
          @input="update('title', $event)"
          >
        </v-text-field>
      </v-col>

      <v-col cols="4" class="pb-0 pt-0 mb-0">
        <v-text-field
          :rules="rulesRequired"
          outlined
          label="Team"
          :value="value.team"
          @input="update('team', $event)">
        </v-text-field>
      </v-col>

      <v-col cols="2" class="pb-0 pt-0 mb-0">
        <v-select
          :rules="toFromRequirements"
          clearable
          outlined
          v-model="value.from"
          :items="fromItems"
          label="From"
        >
        </v-select>
      </v-col>

      <v-col cols="2" class="pb-0 pt-0 mb-0">
        <v-select
          :rules="toFromRequirements"
          clearable
          outlined
          v-model="value.to"
          :items="toItems"
          label="To"
        >
        </v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-0 pb-0 mt-0">
        <v-textarea
          outlined
          rows="1"
          row-height="5"
          auto-grow
          label="Description"
          :value="value.description"
          @input="update('description', $event)">
        </v-textarea>
      </v-col>
    </v-row>

    <v-divider v-if="numOfItems > 1 && myIndexNum < numOfItems - 1">
    </v-divider>
  </section>
</template>

<script>
export default {
	name: 'CoachingExperience',
	// props: [
	//   'numOfItems',
	//   'myIndexNum',
	//   'value',
	//   'completed'
	// ],
	props: {
		value: {
			type: Object,
			// default: () => ({}),
		},
		numOfItems: Number,
		myIndexNum: Number,
		completed: Boolean
	},
	data(){
		return {
			fromDateMenu: false,
			toDateMenu: false,
			select: null,
			rulesRequired: [
				(v) => !!v || 'Required',
			],
			toFromRequirements: [
				(v) => !!v || 'Required',
			]
		}
	},
	computed: {
		fromItems(){
			const listOfYears = []
			let max = new Date()
			max = max.getFullYear()
			if(this.value.to !== null){
				max = this.value.to
			}
			let startYear = max - 100
			for (let index = max; index >= startYear; index--) {
				listOfYears.push(index)
			}
			return listOfYears
		},
		toItems(){
			const listOfYears = []
			let max = new Date()
			max = max.getFullYear()
			let min = max - 100
			if(this.value.from !== null){
				min = this.value.from
			}
			for (let index = max; index >= min; index--) {
				listOfYears.push(index)
			}
			return listOfYears
		},
		items(){
			const listOfYears = []
			let thisYear = new Date()
			thisYear = thisYear.getFullYear()
			let startYear = thisYear - 100
			for (let index = thisYear; index >= startYear; index--) {
				listOfYears.push(index)
			}
			return listOfYears
		},
		hundredYearsAgo(){
			let yearsAgo = new Date()
			yearsAgo.setFullYear(yearsAgo.getFullYear() - 100)
			return yearsAgo
		}
	},
	methods: {
		updateDate(key, date){
			const dateNum = parseInt(date.substr(0, 4))
			this.value[key] = parseInt(date.substr(0, 4))
		},
		update(key, value) {
			this.$emit('input', {...this.value, [key]: value})
		}
	},
	watch: {
		fromDateMenu(val) {
			val && this.$nextTick(() => (this.$refs.fromPicker.activePicker = 'YEAR'))
		},
		toDateMenu(val) {
			val && this.$nextTick(() => (this.$refs.toPicker.activePicker = 'YEAR'))
		}
	}
}
</script>

<style lang='scss'>
.v-text-field.yearDate {
  .v-input__append-inner {
    margin-top: 15px;
  }
}
</style>