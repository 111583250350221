<template>
  <v-card class="pa-6 d-flex flex-column">
    <v-card-text class="flex-grow-1">
      <team-list/>
    </v-card-text>
    <v-card-actions>
      <FormAddArea height="75">
        <RoundedButton color="primary"
          class="px-4 mx-auto" depressed router
          :to="{ name: 'TeamCreate' }"
        icon="add">Add a team</RoundedButton>
      </FormAddArea>
    </v-card-actions>
  </v-card>
</template>

<script>
import TeamList from './TeamList'

export default {
	data() {
		return {
		}
	},
	name: 'TeamManagement',
	components: { TeamList }
}
</script>

<style scoped>

</style>
