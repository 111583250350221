
import { Component, Prop, Vue } from 'vue-property-decorator';
import CoachRecruitingStore from '@/store/targetedSharing/CoachRecruiting.store';
import { getModule } from 'vuex-module-decorators';
import { RecruitingResponse } from '@/../types/enums/recruiting-response';
import { QueryOptions, RepositoryQuery } from 'types/interfaces';
import { TargetedSharingModel } from '@/models/targetedSharing/TargetedSharingModel';
const coachRecruitingSharingStore = getModule(CoachRecruitingStore);

export type loadRecruitingDataFn = (opts?: {query?: RepositoryQuery<TargetedSharingModel>,options?: QueryOptions}) => Promise<void>;

@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	},
})
export default class CoachRecruitingInfoProvider extends Vue{
	@Prop({ default: 'div' }) as: string;
	@Prop({ type: String, default: null }) sharingId: string | null;
	
	async created(): Promise<void> {
		await this.initProfile();
	}
	async loadRecruitingData({
		query = {},
		options = {
			limitPerPage: 5,
			sort:{
				fields: [
					{
						field: 'date',
						desc: true,
					}
				]
			}
		},
	}: {
		query?: RepositoryQuery<TargetedSharingModel>,
		options?: QueryOptions
	} = {}): Promise<void> {
		await coachRecruitingSharingStore.queryRecruitingData({
			coachId: this.sharingId,
			query,
			options,
		});
	}
	loading: boolean = false;
	ready: boolean = false;
	error: boolean = false;
	async initProfile():Promise<void> {
		try{
			this.loading = true;
			this.ready = false;
			if(coachRecruitingSharingStore.queryRecruitingDataInitialized === false && coachRecruitingSharingStore.queryRecruitingDataLoading === false){
				await this.loadRecruitingData();
			}
		}catch(error){
			console.error("Failed to load athlete profile", error);
			this.error = true;
		}finally{
			this.ready = true;
			this.loading = false;
		}
		
	}
	async setResponse({ coachId, id, response }: { coachId: string, id: string, response: RecruitingResponse }): Promise<void>{
		await coachRecruitingSharingStore.setResponse({coachId,id,response});
		await this.loadRecruitingData();
	}
	get SlotProps(): Record<string,any> {
		return {
			Loading: this.loading,
			Ready: this.ready,
			Error: this.error,
			Shares: coachRecruitingSharingStore.totalSharingRecords,
			ShareRecords: coachRecruitingSharingStore.sharingRecords,
			Liked: coachRecruitingSharingStore.totalLikedRecords,
			LikedRecords: coachRecruitingSharingStore.likedRecords,
			Unread: coachRecruitingSharingStore.totalUnseenRecords,
			UnreadRecords: coachRecruitingSharingStore.unseenRecords,

			loadRecruitingData: this.loadRecruitingData.bind(this),
			setResponse: this.setResponse.bind(this),
		}
	}
}
