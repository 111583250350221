
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins';
@Component
export default class ThumbsDownSvg extends Mixins(VuetifyMixin){
	@Prop({ default: 23 }) private size: number;
	@Prop({ default: '#797979' }) private color: string;
	get Color(): string{
		return this.getColor(this.color);
	}
}
