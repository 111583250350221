
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { mdiChevronLeft } from '@mdi/js';

import CreateCoachResumeSummary from '../components/profile/coach/coachResume/CreateCoachResumeSummary.vue';
import CreateCoachResumeExperience from '../components/profile/coach/coachResume/CreateCoachResumeExperience.vue';
import CreateCoachResumeAccolades from '../components/profile/coach/coachResume/CreateCoachResumeAccolades.vue';

import { CoachEditResume } from '../../types/constants/web_client_user.routes'
import { coachResumeCreateStore } from '../store'
import { CoachProfileModel } from '../models/coach/CoachProfileModel';
import { CoachResumeModel } from '../models/coach/CoachResumeModel';
import { MultiStepFormMixin } from '../mixins/MultiStepFormMixin';
import MultiStepForm from '../components/forms/MultiStepForm.vue';
import Page from '../views/Page.vue';
import Alert from '../components/ui/Alert.vue';
import SurveySvg from '../components/svg/SurveySvg.vue';
import { MyCoachMixin } from '../mixins';

@Component({
	components: {
		Alert,
		MultiStepForm,
		Page,
		SurveySvg,
		CreateCoachResumeSummary,
		CreateCoachResumeExperience,
		CreateCoachResumeAccolades,
	},
})

export default class CreateCoachResumeForm extends Mixins(MultiStepFormMixin, MyCoachMixin){
	mdiChevronLeft = mdiChevronLeft;

	formComplete: boolean = false;

	get Loading(): boolean{
		return coachResumeCreateStore.loading;
	}

	get ExistingProfilePhoto(): string{
		if(!this.MyCoachProfile) return "";
		return this.MyCoachProfile.pictureUrl;
	}
	@Watch('ExistingProfilePhoto') setProfilePhoto(): void{
		this.coachResumeForm.step1.pictureUrl = this.ExistingProfilePhoto;
	}
	mounted(): void{
		this.setProfilePhoto();
		this.checkEditResume();
	}

	get IsEdit(): boolean{
		return this.$route.name === CoachEditResume;
	}
	@Watch('MyCoachResume')
	private checkEditResume(){
		if(this.IsEdit){
			this.setupEditResume();
		}
	}
	setupEditResume(): Promise<void>{
		if(!this.MyCoachResume) return;
		this.coachResumeForm.step1.wins = this.MyCoachResume.wins;
		this.coachResumeForm.step1.ties = this.MyCoachResume.ties;
		this.coachResumeForm.step1.losses = this.MyCoachResume.losses;
		this.coachResumeForm.step1.nccp = this.MyCoachResume.nccp;
		this.coachResumeForm.step1.csa = this.MyCoachResume.csa;
		this.coachResumeForm.step1.summary = this.MyCoachResume.summary;
		this.coachResumeForm.step1.philosophy = this.MyCoachResume.philosophy;
		this.coachResumeForm.step1.pictureUrl = this.ExistingProfilePhoto;

		this.coachResumeForm.step2.experience = this.MyCoachResume.experience;

		this.coachResumeForm.step3.awards = this.MyCoachResume.awards;
		this.coachResumeForm.step3.championships = this.MyCoachResume.championships;
		this.coachResumeForm.step3.athletesCoached = this.MyCoachResume.athletesCoached;
		this.coachResumeForm.step3.certifications = this.MyCoachResume.certifications;
	}

	currentStep = 1;
	steps = 3;
	resetForm(): void{
		this.coachResumeForm = this.defaultForm();
	}
	coachResumeForm = this.defaultForm();
	private defaultForm(): Record<string, any>{
		return {
			step1: {
				valid: true,
				wins: null,
				losses: null,
				ties: null,
				nccp: null,
				csa: null,
				summary: null,
				philosophy: null,
				pictureUrl: this.ExistingProfilePhoto,
			},
			step2: {
				valid: true,
				experience: [],
			},
			step3: {
				valid: true,
				awards: [],
				championships: [],
				athletesCoached: [],
				certifications: []
			}
		}
	}

	get CurrentStepValid(): boolean {
		const step = this.coachResumeForm[this.CurrentStepKey];
		return step ? step.valid : true;
	}

	finish(): void{
		let resume = new CoachResumeModel();
		resume.wins = this.coachResumeForm.step1.wins;
		resume.losses = this.coachResumeForm.step1.losses;
		resume.ties = this.coachResumeForm.step1.ties;
		resume.nccp = this.coachResumeForm.step1.nccp;
		resume.csa = this.coachResumeForm.step1.csa;
		resume.summary = this.coachResumeForm.step1.summary;
		resume.philosophy = this.coachResumeForm.step1.philosophy;

		resume.experience = this.coachResumeForm.step2.experience;

		resume.awards = this.coachResumeForm.step3.awards;
		resume.championships = this.coachResumeForm.step3.championships;
		resume.athletesCoached = this.coachResumeForm.step3.athletesCoached;
		resume.certifications = this.coachResumeForm.step3.certifications;

		if(this.IsEdit){
			const userCoach = this.MyCoachProfile.copy();
			userCoach.resume = resume;
			userCoach.pictureUrl = this.coachResumeForm.step1.pictureUrl;
			console.groupCollapsed('EditCoachResume');
			console.log("Edit Coach Resume", { CoachResume: this.coachResumeForm })
			coachResumeCreateStore.patchCoachResume(userCoach).then((newCoachAndResume: CoachProfileModel) => {
				console.log("Coach Resume Edited!", { newCoachAndResume });
				console.groupEnd();
				this.formComplete = true;
			});
		}else{
			const userCoach = new CoachProfileModel().load(this.MyCoachProfile);
			userCoach.resume = resume;
			userCoach.pictureUrl = this.coachResumeForm.step1.pictureUrl;
			console.groupCollapsed('CreateCoachResume');
			console.log("Create Coach Resume", { CoachResume: this.coachResumeForm })
			coachResumeCreateStore.createCoachResume(userCoach).then((newCoachAndResume: CoachProfileModel) => {
				console.log("Coach Resume Created!", { newCoachAndResume });
				console.groupEnd();
				this.formComplete = true;
			});
		}
	}

	cancel(): void {
		this.$router.push('/')
	}

	private viewResume(){
		this.$router.push({
			name: 'CoachProfile',
			params:{
				coachId: this.MyCoachId,
			}
		});
	}
	private goToDashboard(){
		this.$router.push('/');
	}
}
