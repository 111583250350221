
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, Prop, Vue} from 'vue-property-decorator';
import ProfilePictureUpload from '@/components/forms/ProfilePictureUpload.vue';

@Component({
	components: {
		ProfilePictureUpload,
	}
})
export default class CreateCoachResumeSummary extends Vue{

	@Prop({ required: true }) value: Record<string,string>;

	showImageUploadDialog = false;
	valid = false;
	numberRules = [
		v => v >= 0 || 'Cannot be negative',
	];
	rulesRequired = [
		(v) => !!v || 'Required',
	];

	submit() {
		this.$emit('submit');
	}
	update(key, value) {
		this.$emit('input', {...this.value, [key]: value});
	}
}
