
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiDotsHorizontal, mdiChevronDown, mdiPencil, mdiContentDuplicate, mdiCloseCircle, mdiClipboardTextOutline, mdiPlus } from '@mdi/js';
import ContextMenu from '../../ui/ContextMenu.vue';
import SoccerDepthChart from './Soccer/SoccerDepthChart.vue';
import { VuetifyMixin } from '../../../mixins/VuetifyMixin';
import { FormRulesMixin } from '../../../mixins/FormRulesMixin';
import { TeamModel, PlayerOnTeam } from '../../../models/team';
import { DepthChartPosition, DepthChartModel } from '../../../models/depthChart';
import EditPositionOverlay from '@/components/teams/DepthChart/EditPositionOverlay.vue';
import { teamDashboardStore } from '../../../store';
import { CreateDepthChartFormData } from '@/../types/interfaces';
import NewDepthChartForm from '@/components/teams/DepthChart/NewDepthChartForm.vue';


@Component({
	components: { ContextMenu, EditPositionOverlay, SoccerDepthChart, NewDepthChartForm },
})
export default class DepthChartV2 extends Mixins(FormRulesMixin, VuetifyMixin){
	mdiDotsHorizontal = mdiDotsHorizontal;
	mdiChevronDown = mdiChevronDown;
	mdiClipboardTextOutline = mdiClipboardTextOutline;
	get HeaderStyle(): Record<string, string>{
		return {
			'border-top': `1px solid #074E69 !important`,
			'background-color': this.getColor('baColorDeepBlue'),
		}
	}
	get DepthCharts(): DepthChartModel[]{
		return this.team.depthCharts;
	}
	get DepthChartLoading(): boolean{
		return teamDashboardStore.saveDepthChartLoading || teamDashboardStore.deleteDepthChartLoading;
	}

	get SelectedTeamPlayers(): PlayerOnTeam[]{
		return this.team.players;
	}

	get DepthChartEditPositionData(): DepthChartPosition{
		return teamDashboardStore.editPositionData;
	}

	async savePosition(positionData: DepthChartPosition): Promise<void>{
		this.showEditPosition = false;
		await teamDashboardStore.saveDepthChartPosition({
			depthChartId: teamDashboardStore.editDepthChartId,
			position: positionData
		});
	}

	async cancelEdit(): Promise<void>{
		this.showEditPosition = false;
		await teamDashboardStore.cancelDepthChartPosition();
	}

	get DepthChartCols(): Record<string, any>{
		if(this.MediumAndDown){
			return {
				cols: 12,
			};
		}
		return {
			cols: 8
		};
	}

	get MediumAndDown(): boolean{
		return this.$vuetify.breakpoint.mdAndDown;
	}

	@Prop({ required: true }) private team: TeamModel;
	@Prop({ default: 'baColorSuperLightBlue' }) private color: string;
	@Prop({ default: 'baColorPrimaryBlue' }) private borderColor: string;
	contextMenuItems = [
		{ icon: mdiPencil, text: "Rename", click: this.confirmRename },
		{ icon: mdiContentDuplicate, text: "Duplicate", click: this.confirmDuplicate},
		{ icon: mdiCloseCircle, text: "Delete", click: this.confirmDepthChart },
		{ icon: mdiPlus, text: "New Depth Chart", click: this.newDepthChart },
	];

	get SelectedDepthChartId(): string{
		return teamDashboardStore.selectedDepthChartId;
	}
	get SelectedDepthChart(): DepthChartModel{
		return teamDashboardStore.SelectedDepthChart;
	}
	async selectDepthChart(id?: string): Promise<void>{
		await teamDashboardStore.selectDepthChart(id);
	}

	get ConfirmDeleteTitle(): string{
		return this.SelectedDepthChart ? `Delete "${this.SelectedDepthChart.name}"?` : 'Delete?';
	}
	showDeleteDialog: boolean = false;
	confirmDepthChart(): void{
		this.showDeleteDialog = true;
	}
	async deleteSelectedDepthChart(): Promise<void>{
		this.showDeleteDialog = false;
		await teamDashboardStore.deleteDepthChart({
			teamId: this.team.id,
			depthChartId: this.SelectedDepthChartId,
		});
	}

	get ConfirmDuplicateTitle(): string{
		return this.SelectedDepthChart ? `Make a copy of "${this.SelectedDepthChart.name}"?` : 'Make a copy of this depth chart?';
	}
	showDuplicateDialog: boolean = false;
	duplicateNameValid: boolean = true;
	duplicateName: string = "";
	confirmDuplicate(): void{
		this.duplicateName = `Copy of ${this.SelectedDepthChart.name}`;
		this.showDuplicateDialog = true;
	}
	async duplicateSelectedDepthChart(): Promise<void>{
		this.showDuplicateDialog = false;
		await teamDashboardStore.duplicateDepthChart({
			teamId: this.team.id,
			name: this.duplicateName,
			depthChartId: this.SelectedDepthChartId,
		});
	}

	get ConfirmRenameTitle(): string{
		return this.SelectedDepthChart ? `Rename "${this.SelectedDepthChart.name}"?` : 'Rename this depth chart?';
	}
	showRenameDialog: boolean = false;
	renameNameValid: boolean = true;
	newName: string = "";
	confirmRename(): void{
		this.newName = this.SelectedDepthChart.name;
		this.showRenameDialog = true;
	}
	async renameSelectedDepthChart(): Promise<void>{
		this.showRenameDialog = false;
		await teamDashboardStore.renameDepthChart({
			teamId: this.team.id,
			newName: this.newName,
			depthChartId: this.SelectedDepthChartId,
		});
	}

	addDepthChart: boolean = false;
	newDepthChart(): void{
		this.addDepthChart = true;
	}

	async createDepthChart(depthChartForm: CreateDepthChartFormData): Promise<void>{
		await teamDashboardStore.createDepthChart({
			teamId: this.team.id,
			depthChartForm,
		}).catch((e) => console.error(e));
		this.addDepthChart = false;
	}

	showEditPosition: boolean = false;
	set ShowEditPosition(showEditPosition: boolean){
		if(showEditPosition === false){
			this.cancelEdit();
		}
		this.showEditPosition = showEditPosition;
	}
	get ShowEditPosition(): boolean{
		return this.showEditPosition;
	}
	editPosition(position: DepthChartPosition): void{
		teamDashboardStore.editDepthChartPosition({
			depthChartId: this.SelectedDepthChart.id,
			position,
		});
		this.showEditPosition = true;
	}
}
