<template>
  <section>
    <v-form
      v-model="value.valid"
    >
      <v-row>
        <v-col>
          <FormGroupHeading>
            Coaching Experience (Optional)
          </FormGroupHeading>
        </v-col>
      </v-row>
      <coaching-experience v-for="(oneExperienceItem, index) in value.experience" :key="index"
        v-model="value.experience[index]"
        @input="updateItem(value.experience[index], $event)"
        :numOfItems="value.experience.length"
        :myIndexNum="index"
        :completed="itemCompleted(value.experience[index])"
        @removeItem="removeItem(value.experience, index)"
        />
      <FormAddArea
        v-if="previousItemCompleted(value.experience)"
        @click="addExperience(value.experience)"
        :button-text="noItems(value.experience) ? 'Add position' : 'Add another position'"
      />
    </v-form>
  </section>
</template>

<script>
import CoachingExperience from '@/components/profile/coach/coachResume/CoachingExperience.vue'
import FormAddArea from '@/components/forms/FormAddArea'

export default {
	props: {
		value: {
			type: Object,
			default: () => ({}),
		},
	},
	components: {
		CoachingExperience
	},
	methods: {
		noItems(list){
			if(!list || list.length === 0){
				return true
			}
		},
		previousItemCompleted(list){
			if(!list || list.length == 0){
				return true
			}
			const length = list.length
			// Yeah. We can change this formatting, or loop ¯\_(ツ)_/¯
			if(
				list[length - 1]
        && list[length - 1].title 
        && list[length - 1].team 
        && list[length - 1].from 
        && list[length - 1].to ){
				return true
			}
			return false
		},
		removeItem(list, index){
			list.splice(index, 1);
		},
		itemCompleted(item){
			if(item){
				return true
			}
			return false
		},
		submit() {
			this.$emit('submit')
		},
		addExperience(list){
			list.push({
				title: null,
				team: null,
				from: null,
				to: null,
				description: null,
			})
		},
		updateItem(item, value) {
			item = value
			this.$emit('input', this.value)
		},
	},
	data () {
		return {
			coachingExperienceList: [{
				title: '',
				team: '',
			}],
			something: {},
			numOfExperienceItems: 1,
		}
	},
}
</script>

<style>

</style>