
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import { mdiMagnify, mdiChevronDown } from '@mdi/js';
import { VuetifyMixin } from '../../mixins/VuetifyMixin';
import { AthleteAutocompleteInfo } from '@best-athletes/ba-types';
import { DebounceMixin } from '@/mixins/DebounceMixin';
import { athleteApi } from '@/api/AthleteApi';
import AthletePreview from './AthletePreview.vue';
@Component({
	components:{
		AthletePreview,
	}
})
export default class AthleteAutocomplete extends Mixins(DebounceMixin, VuetifyMixin){
	mdiMagnify = mdiMagnify;
	mdiChevronDown = mdiChevronDown;
	loading: boolean = false;
	@Prop({ default: () => (["id", "name"]) }) searchFields: string[];
	@Prop({ default: 8 }) limitPerPage: number;
	@Prop({ default: undefined }) itemValue: string | undefined;
	@Prop({ type: String, default: "Keep typing to find an athlete..." }) noDataText: string;
	@Prop({ default: true, type: Boolean }) returnObject: boolean;
	@Prop({ default: false, type: Boolean }) clearOnSelect: boolean;
	@Prop({ default: null }) itemsFilter: (item: AthleteAutocompleteInfo) => boolean = null;
	@Prop({ required: true }) value: AthleteAutocompleteInfo;
	@Prop({ required: true }) resource: string;
	input(value: AthleteAutocompleteInfo): void{
		this.$emit('input', value);
	}

	onChange(): void{
		if(this.clearOnSelect){
			this.$nextTick(() => {
				this.search = '';
				this.value = null;
			});
		}
	}

	get Api(): typeof athleteApi{
		return athleteApi
	}

	search: string = "";
	items: AthleteAutocompleteInfo[] = [];
	get SearchItems(): AthleteAutocompleteInfo[]{
		if(this.itemsFilter === null) return this.items;
		return this.items.filter(this.itemsFilter);
	}

	@Watch('search') debounceSearch(): void{
		this.loading = true;
		this.debounceCallback('execSearch', this.execSearch.bind(this), 150);
	}
	
	async execSearch(): Promise<void>{
		this.loading = true;
		try{
			const res = await this.Api.athleteAutocomplete(this.search, {
				limitPerPage: this.limitPerPage,
			});
			this.items = res.docs;
		}catch(e){
			console.error(`Failed to query ${this.resource}`, e);
		}finally{
			this.loading = false;
		}
	}

}
