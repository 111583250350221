
import { Component, Mixins, Prop } from "vue-property-decorator";
import Page from "@/views/Page.vue";
import { VuetifyMixin, MyCoachMixin } from "@/mixins";
import { CoachScoutingSummary } from "@/../types/constants/web_client_user.routes";
import ScoutingReportDetailProvider, { SaveReportFn } from "@/components/hoc/ScoutingReportDetailProvider.vue";
import ScoutingReportForm from "@/components/scoutingReport/ScoutingReportForm.vue";
import { ScoutingReportModel } from '@/models/scoutingReport/ScoutingReportModel';
import { notificationStore } from '@/store';

@Component({
	components: {
		Page,
		ScoutingReportDetailProvider,
		ScoutingReportForm,
	},
})
export default class CoachScoutingReportForm extends Mixins(VuetifyMixin, MyCoachMixin, ) {
	@Prop({ default: null, type: String }) reportId: string | null;

	loading: boolean = false;

	get AthleteId(): string | null {
		return this.$route.params.athleteId;
	}
	goToScoutingSummary(): void{
		this.$router.push({
			name: CoachScoutingSummary,
			params:{
				...this.$route.params,
			}
		});
	}

	async onSubmit(saveReport: SaveReportFn, report: ScoutingReportModel, submit: boolean = false): Promise<void>{
		this.loading = true;
		try{
			await saveReport(report, submit);
			if (submit) {
				this.goToScoutingSummary();
			}
		}catch(e){
			console.error("Failed to save scouting report", e);
			notificationStore.pushLocalNotificationMessage("Failed to save scouting report. Please try again later.");
		}finally{
			notificationStore.pushSnackbarSuccess({message:"Scouting Report saved."})
			this.loading = false;
		}
	}

	/* Save the report without submit */
	async onSave(saveReport: SaveReportFn, report: ScoutingReportModel): Promise<void>{
		return this.onSubmit(saveReport, report, false)
	}
}
