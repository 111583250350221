<template>
  <section>
    <v-row align="center">
      <v-col>
        <v-text-field
          outlined
          :rules="rulesRequired"
          label="Description of Certification"
          :hint="hint"
          persistent-hint
          :value="value.description"
          @input="update('description', $event)">
        </v-text-field>
      </v-col>
      
      <v-col cols="1">
        <v-icon v-if="completed" @click="$emit('removeItem')" color="#81BAED">cancel</v-icon>
      </v-col>
    </v-row>

    <v-divider v-if="numOfItems > 1 && myIndexNum < numOfItems - 1">
    </v-divider>
  </section>
</template>

<script>
export default {
	name: 'CertificationItem',
	props: {
		value: {
			type: Object,
			// default: () => ({}),
		},
		hint: String,
		numOfItems: Number,
		myIndexNum: Number,
		completed: Boolean
	},
	data(){
		return{
			rulesRequired: [
				(v) => !!v || 'Required',
			],
		}
	},
	methods: {
		update(key, value) {
			this.$emit('input', {...this.value, [key]: value})
		}
	}
}
</script>

<style>

</style>