
import { Component, Vue, Prop } from 'vue-property-decorator';
import CalendarProvider from '@/components/hoc/CalendarProvider.vue';
import TeamGames from '@/components/teams/TeamGames.vue';
import { formatDateHyphensYYYYMMDD } from '@/helpers';

@Component({
	components: { CalendarProvider, TeamGames },
})
export default class TeamGamesProvider extends Vue {
	@Prop({ type: String, required: true }) teamId: string;
	@Prop({ type: String, default: false }) readOnly: string;

	@Prop({ default: () => formatDateHyphensYYYYMMDD(new Date()) }) focusDate: string;
	updateFocusDate(val: string): void{
		this.focusDate = val;
	}
}
