<template>
  <section>
    <v-row align="center">
      <v-col cols="6">
        <v-text-field
          outlined
          :rules="rulesRequired"
          label="Player Name"
          :hint="hint"
          persistent-hint
          :value="value.athleteName"
          @input="update('athleteName', $event)">
        </v-text-field>
      </v-col>

      <v-col cols="3">
        <v-text-field
          outlined
          :rules="rulesRequired"
          label="Team"
          :value="value.team"
          @input="update('team', $event)">
        </v-text-field>
      </v-col>

      <v-col cols="2" class="pb-0 pt-0 mb-0">
        <v-select
          clearable
          :rules="rulesRequiredArray"
          outlined
          v-model="value.year"
          :items="items"
          label="Year"
          multiple
          @change="sortSelection"
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">
              {{ item }} 
            </span>
            <span
              v-if="index === 1"
              class="grey--text caption ml-1"
            >({{value.year.length}})</span>
          </template>
        </v-select>
        <!-- <v-menu 
          ref="yearDateMenu"
          v-model="yearDateMenu"
          transition="scale-transition">
          <template v-slot:activator="{ on }">
            <v-text-field
              class="year"
              :value="value.year"
              @input="update('year', $event)"
              label="Year"
              required
              readonly
              v-on="on"/>
          </template>
          <v-date-picker 
            ref="yearPicker"
            @update:picker-date="updateDate('year', $event)"
            :min="hundredYearsAgo.toISOString().substr(0, 10)"
            :max="new Date().toISOString().substr(0, 10)"
            class="pb-0 mb-0"/>
        </v-menu> -->
      </v-col>

      <v-col cols="1">
        <v-icon v-if="completed" @click="$emit('removeItem')" color="#81BAED">cancel</v-icon>
      </v-col>

    </v-row>

    <v-divider v-if="numOfItems > 1 && myIndexNum < numOfItems - 1">
    </v-divider>
  </section>
</template>

<script>
export default {
	name: 'AthleteCoachedItem',
	props: {
		value: {
			type: Object,
			// default: () => ({}),
		},
		hint: String,
		numOfItems: Number,
		myIndexNum: Number,
		completed: Boolean,
	},
	data(){
		return{
			yearDateMenu: false,
			rulesRequired: [
				(v) => !!v || 'Required',
			],
			rulesRequiredArray: [
				(v) => !!(v && v.length) || 'Required'
			]
		}
	},
	computed: {
		items(){
			const listOfYears = []
			let thisYear = new Date()
			thisYear = thisYear.getFullYear()
			let startYear = thisYear - 100
			for (let index = thisYear; index >= startYear; index--) {
				listOfYears.push(index)
			}
			return listOfYears
		},
		hundredYearsAgo(){
			let yearsAgo = new Date()
			yearsAgo.setFullYear(yearsAgo.getFullYear() - 100)
			return yearsAgo
		}
	},
	methods: {
		updateDate(key, date){
			const dateNum = parseInt(date.substr(0, 4))
			this.value[key] = parseInt(date.substr(0, 4))
		},
		update(key, value) {
			this.$emit('input', {...this.value, [key]: value})
		}
	},
	watch: {
		yearDateMenu(val) {
			val && this.$nextTick(() => (this.$refs.yearPicker.activePicker = 'YEAR'))
		},
	}
}
</script>

<style>

</style>