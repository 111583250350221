import { Component, Vue } from 'vue-property-decorator';
import { DominantSide, DominantSideValues } from "@/../types/enums";
import { SelectorItem } from './selectorItem';
import { title } from '@/pipes';

@Component
export class DominantSideSelectorMixin extends Vue {
	get AvailableDominantSideValues(): SelectorItem<DominantSide>[] {
		return DominantSideValues.map(value => ({
			text: title(value),
			value: value,
		}));
	}
}