
import { Component, Mixins, Prop } from "vue-property-decorator";
import Page from "@/views/Page.vue";
import { VuetifyMixin, MyCoachMixin, DebounceMixin } from "@/mixins";

import CoachRecruitingInfoProvider, { loadRecruitingDataFn } from "@/components/hoc/CoachRecruitingInfoProvider.vue";
import CoachRecruitingTable from "@/components/sharing/CoachRecruitingTable.vue";
import { Route } from 'vue-router';
import { TargetedSharingModel } from '@/models/targetedSharing/TargetedSharingModel';
import { RecruitingResponse } from '@/../types/enums/recruiting-response';
import { CoachViewAthlete } from '@/../types/constants/web_client_user.routes';
import { DataOptions } from 'vuetify';
import { RepositoryQuery, QueryOptions } from '@/../types/interfaces';

@Component({
	components: {
		Page,
		CoachRecruitingInfoProvider,
		CoachRecruitingTable,
	},
	
})
export default class CoachRecruitingSummary extends Mixins(
	DebounceMixin,
	MyCoachMixin,
	VuetifyMixin,
) {
	search: string = "";
	responseFilters: {text: string, value: RecruitingResponse}[] = [];
	tableOptions: DataOptions = {
		page: 0,
		itemsPerPage: 15,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [false],
		multiSort: false,
		mustSort: false,
	};
	get SharingId(): string{
		return this.MyCoachId;
	}
	@Prop({ type: String, default: 'shares' }) tab: string;
	toRoute(tab: string): Partial<Route>{
		return {
			...this.$route,
			params:{
				...this.$route.params,
				tab: tab,
			}
		};
	}

	get CurrentTab(): string{
		return this.tab;
	}

	getResponseHandlers(setResponse: ({coachId,id,response}: {coachId: string,id: string,response: RecruitingResponse}) => Promise<void>): Record<string, any>{
		return {
			'click:view': ({athleteId,}: {athleteId: string}) => {
				this.$router.push({
					name: CoachViewAthlete,
					params:{
						...this.$route.params,
						athleteId,
					}
				})
			},
			'click:no': ({id,share}: {id: string, share: TargetedSharingModel}) => {
				if(share.recipientData.response === RecruitingResponse.No){
					setResponse({ coachId: this.SharingId, id, response: null})
				}else{
					setResponse({ coachId: this.SharingId, id, response: RecruitingResponse.No})
				}
			},
			'click:maybe': ({id,share}: {id: string, share: TargetedSharingModel}) => {
				if(share.recipientData.response === RecruitingResponse.Maybe){
					setResponse({ coachId: this.SharingId, id, response: null})
				}else{
					setResponse({ coachId: this.SharingId, id, response: RecruitingResponse.Maybe})
				}
			},
			'click:yes': ({id,share}: {id: string, share: TargetedSharingModel}) => {
				if(share.recipientData.response === RecruitingResponse.Yes){
					setResponse({ coachId: this.SharingId, id, response: null})
				}else{
					setResponse({ coachId: this.SharingId, id, response: RecruitingResponse.Yes})
				}
			},
		};
	}


	tableUpdatePending: boolean = false;
	debounceUpdateTable(load: loadRecruitingDataFn): void{
		this.tableUpdatePending = true;
		this.debounceCallback('updateTable', async () => {
			try{
				await this.updateTable(load);
			}catch(e){
				console.error("Failed to update table");
			}finally{
				this.tableUpdatePending = false;
			}
		}, 400);
	}
	async updateTable(load: loadRecruitingDataFn): Promise<void>{
		return await this.loadRecruitingData(load);
	}

	tableLoading: boolean = false;
	async loadRecruitingData(load: loadRecruitingDataFn): Promise<void>{
		this.tableLoading = true;
		try{
			const query: RepositoryQuery<TargetedSharingModel> = {
				search: this.search,
				fields: ['email'],
				$match:{
					['recipientData.response' as any]: {
						$any: this.responseFilters.map(res => res.value),
					}
				}
			};
			const options: QueryOptions = { 
				page: this.tableOptions.page,
				limitPerPage: this.tableOptions.itemsPerPage,
			};
			if(this.tableOptions.sortBy.length > 0){
				options.sort = {
					fields: this.tableOptions.sortBy.map((field, index) => {
						return {
							field: field,
							desc: this.tableOptions.sortDesc[index],
						};
					}),
				};
			}
			await load({ query, options });
		}catch(e){
			console.error(e);
		}finally{
			this.tableLoading = false;
		}
	}

}
