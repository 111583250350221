<template>
  <v-container class="mt-10">
    <v-row>
          <v-col cols="4">
            <user-avatar :email="email" :firstName="firstName" :lastName="lastName" :pictureUrl="pictureUrl"/>
          </v-col>
      <v-col cols="8">
        <p>
          <strong>{{ firstName }}</strong>{{' '}}
          <strong>{{ lastName }}</strong>
        </p>
        <p>
          {{ city }}
          {{ country }}
        </p>

        <ul v-for="sport in sports" :key="sport.position"><li>{{sport.position}}</li></ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>


import UserAvatar from '../ui/UserAvatar';
export default {
	computed: {},
	components: {
		UserAvatar
	},
	props: ['firstName', 'lastName', 'city', 'country', 'pictureUrl', 'sport', 'position', 'email', 'sports']
}
</script>

<style scoped lang="scss">

</style>
