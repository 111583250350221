
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import EventTypesGroup from './EventTypesGroup.vue';
import EventsAgenda from './EventsAgenda.vue';
import { VuetifyMixin } from '../../mixins';
import BATextField from '@/components/inputs/BATextField.vue';
import CopyToClipboard from '@/components/ui/CopyToClipboard.vue';
import Dialog from "@/components/ui/Dialog.vue";
import FormGroupHeading from "@/components/ui/FormGroupHeading.vue";
import { ExportSvg } from "@/components/svg/ExportSvg.vue";
import SectionHeading from "@/components/ui/SectionHeading.vue";
import { getMonthName, formatDate } from '@/helpers';
import { CalendarMode } from './types/CalendarMode';

@Component({
	components: { EventsAgenda, EventTypesGroup, Dialog, FormGroupHeading, BATextField, CopyToClipboard, ExportSvg, SectionHeading }
})
export default class CalendarMonthSelector extends Mixins(VuetifyMixin){
	mdiChevronLeft = mdiChevronLeft;
	mdiChevronRight = mdiChevronRight;
	$refs: {
		calendar: any
	};
	@Prop({ type: String, default: 'baColorVibrantBlueText' }) accentColor: string;
	@Prop({ type: String, default: 'baColorAppForeground' }) color: string;
	@Prop({ type: String, default: CalendarMode.Month }) view: CalendarMode;
	@Prop({ type: String }) focusDate: string;
	updateFocusDate(focusDate: string): void{
		this.$emit('update:focus-date', focusDate);
	}

	get CalendarTimeTitle(): string{
		if (this.$refs.calendar && this.view !== CalendarMode.Agenda){
			return this.$refs.calendar.title ? this.$refs.calendar.title : this.CurrentMonthName + ' ' + this.CurrentYear
		} else {
			return this.CurrentMonthName + ' ' + this.CurrentYear
		}
	}
	get CurrentDate(): Date{
		return new Date(this.focusDate);
	}
	get CurrentMonthName(): string{
		return getMonthName(this.CurrentDate);
	}
	get CurrentYear(): number{
		return this.CurrentDate.getUTCFullYear(); 
	}

	resetDate(override?: Date): void{
		if(override === undefined){
			this.updateFocusDate(formatDate(new Date()));
		}else{
			this.updateFocusDate(formatDate(override));
		}
	}
	prev (): void {
		if (this.view === CalendarMode.Month || this.view === CalendarMode.Agenda){
			const currentDate = new Date(this.focusDate)
			const newDate = new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 0)
			this.updateFocusDate(formatDate(newDate));
		} else if (this.view === CalendarMode.Week){
			const currentDate = new Date(this.focusDate)
			const newDay = currentDate.getUTCDate()-7
			const newDate = new Date(currentDate.setUTCDate(newDay))
			this.updateFocusDate(formatDate(newDate));
		} else if (this.view === CalendarMode.Day){
			const currentDate = new Date(this.focusDate)
			const newDay = currentDate.getUTCDate()-1
			const newDate = new Date(currentDate.setUTCDate(newDay))
			this.updateFocusDate(formatDate(newDate));
		} else if (this.view === CalendarMode.FourDay){
			const currentDate = new Date(this.focusDate)
			const newDay = currentDate.getUTCDate()-4
			const newDate = new Date(currentDate.setUTCDate(newDay))
			this.updateFocusDate(formatDate(newDate));
		}
	}
	next (): void {
		if (this.view === CalendarMode.Month || this.view === CalendarMode.Agenda){
			const currentDate = new Date(this.focusDate)
			const newDate = new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth()+1, 1)
			this.updateFocusDate(formatDate(newDate));
		} else if (this.view === CalendarMode.Week){
			const currentDate = new Date(this.focusDate)
			const newDay = currentDate.getUTCDate()+7
			const newDate = new Date(currentDate.setUTCDate(newDay))
			this.updateFocusDate(formatDate(newDate));
		} else if (this.view === CalendarMode.Day){
			const currentDate = new Date(this.focusDate)
			const newDay = currentDate.getUTCDate()+1
			const newDate = new Date(currentDate.setUTCDate(newDay))
			this.updateFocusDate(formatDate(newDate));
		} else if (this.view === CalendarMode.FourDay){
			const currentDate = new Date(this.focusDate)
			const newDay = currentDate.getUTCDate()+4
			const newDate = new Date(currentDate.setUTCDate(newDay))
			this.updateFocusDate(formatDate(newDate));
		}
	}
}
