<template>
  <v-container class="mt-10">
    <v-row>
      <v-col cols="12" md="12">
        <h1>Find Athletes</h1>
        <h4 class="mt-5">Search by name</h4>
        <v-autocomplete
                v-model="model"
                :items="items"
                :loading="isLoading"
                :search-input.sync="search"
                color="white"
                hide-no-data
                hide-selected
                item-text="Description"
                item-value="API"
                label="Athlete Name"
                placeholder="Start typing to Search"
                return-object
        />
      </v-col>
    </v-row>

    <v-progress-circular indefinite v-if="loadingAggregations === true"/>
    <v-row v-if="loadingAggregations === false">
      <v-col cols="12">
        <h4 class="mt-5">Search by critera</h4>
      </v-col>
      <v-col md="4" cols="12">
        <v-select :items="aggregationAge"
                  label="Age"
                  multiple
                  placeholder="Select one or more ages"
                  v-model="filterAge"/>
      </v-col>
      <v-col md="4" cols="12">
        <v-select :items="aggregationGradYear"
                  label="Graduation Year"
                  multiple
                  placeholder="Select one or more years"
                  v-model="filterGradYear"/>
      </v-col>
      <v-col md="4" cols="12">
        <v-select :items="['Male','Female']"
                  label="Gender"
                  placeholder="Choose a gender"
                  v-model="filterGender"/>
      </v-col>
      <v-col md="4" cols="12">
        <v-select :items="aggregationCountry"
                  label="Country"
                  placeholder="Choose one or more countries"
                  v-model="filterCountry"/>
      </v-col>
      <v-col md="4" cols="12">
        <v-select :items="aggregationSport"
                  label="Sport"
                  placeholder="Select a sport"
                  v-model="filterSport"/>
      </v-col>
      <v-col md="4" cols="12">
        <v-select :items="aggregationPosition"
                  label="Position"
                  multiple
                  placeholder="Select one ore more positions"
                  v-model="filterPosition"/>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-btn color="primary" @click="filter()">Search</v-btn>
      </v-col>
    </v-row>


    <v-row class="search-results">
      <v-progress-circular indefinite v-if="searching === true"/>
      <v-col cols="4" v-if="searching === false && athletes.length === 0">
        No results found
      </v-col>
      <v-col cols="4" v-if="athletes.length > 0">
        <strong>{{athletes.length}} athletes were found matching your search</strong>
<!--{{results[0].firstName}}-->
<!--{{results[0].lastName}}-->
        <athlete-search-result v-for="athlete in athletes"
                               :firstName="athlete.firstName"
                               :lastName="athlete.lastName"
                               :email="athlete.email"
                               :key="athlete.id"
                               :athlete="athlete"
                               :city="athlete.city"
                               :country="athlete.country"
                               :sports="athlete.sports"
                               :birthDate="athlete.birthDate"/>
      </v-col>
    </v-row>

    <br>
    <br>
    <br>
    <br>
    <br>
    <hr/>
    <h1>Dev Only</h1>
    <br>
    <v-btn @click="tempBuildAll()">Rebuild index</v-btn>
    <br>
    Indexed: {{tempIndexed}}
    <hr/>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import api from '@/api/athlete'
import AthleteSearchResult from '../components/profile/AthleteSearchResult'

export default {
	computed: {
		...mapGetters('athletes', {athletes: 'list'}),
		fields () {
			if (!this.model) return []

			return Object.keys(this.model).map(key => {
				return {
					key,
					value: this.model[key] || 'n/a',
				}
			})
		},
		items () {

			return this.entries.map(entry => {
				// const Description = entry.Description.length > this.descriptionLimit
				//   ? entry.Description.slice(0, this.descriptionLimit) + '...'
				//   : entry.Description
				//
				// return Object.assign({}, entry, { Description })

				return {Description: `${entry.firstName} ${entry.lastName}`}
			})
		}
	},


	components: {
		AthleteSearchResult
	},
	async created() {
		this.clearAthletes();
		// do a "search" to get list of athlete aggregations
		this.loadingAggregations = true
		const aggregations = await api.getAggregations();
		this.loadingAggregations = false
		this.aggregationAge = aggregations.birthDate.buckets.map(item => {return {text: item.key_as_string, value: item.key}});
		this.aggregationCity = aggregations.city.buckets.map(item => {return {text: item.key_as_string, value: item.key}});
		this.aggregationCountry = aggregations.country.buckets.map(item => {return {text: item.key, value: item.key}});
		this.aggregationGradYear = aggregations.gradYear.buckets.map(item => {return {text: item.key_as_string, value: item.key}});
		this.aggregationSport = aggregations.sports.buckets.map(item => {return {text: item.key, value: item.key}});
		this.aggregationPosition = aggregations.positions.buckets.map(item => {return {text: item.key, value: item.key}});
	},
	data() {
		return {
			advancedSearch: false,
			aggregationAge: null,
			aggregationCity: null,
			aggregationCountry: null,
			aggregationGradYear: null,
			aggregationPosition: null,
			aggregationSport: null,
			entries: [],
			filterAge: null,
			filterCity: null,
			filterCountry: null,
			filterGender: null,
			filterGradYear: null,
			filterPosition: null,
			filterSport: null,
			isLoading: false,
			loadingAggregations: true,
			model: null,
			search: null,
			searching: false,
			tempIndexed: 0
		}
	},
	methods: {
		//...mapActions('user', ['fetchCoreProfile', 'updateUserInfo']),
		...mapActions('athletes', {clearAthletes: 'clear', loadFilteredAthletes: 'loadFiltered'}),
		// @TODO REMOVE BEFORE PROD
		async tempBuildAll() {
			await api.buildAll()
				.then(res => {
					//const { count, entries } = res
					//this.count = count
					this.tempIndexed = res.indexed.athletes;
					console.log(res)
				})
				.catch(err => {
					console.log(err)
				})
		},
		async filter() {
			//this.results = await api.getAll({
			this.searching = true
			this.clearAthletes()
			this.loadFilteredAthletes({
				'filters[age]': this.filterAge,
				'filters[country]': this.filterCountry,
				'filters[city]': this.filterCity,
				'filters[gender]': this.filterGender,
				'filters[gradYear]': this.filterGradYear,
				'filters[positions]': this.filterPosition,
				'filters[sports]': this.filterSport
			});
			this.searching = false

		},
		// async autocomplete(val) {
		//
		//   if (this.items.length > 0) return
		//
		//   // Items have already been requested
		//   if (this.isLoading) return
		//
		//   this.isLoading = true
		//
		//   // Lazily load input items
		//   this.completionSearch = await this.loadFilteredAthletes({s:val})
		//     //api.getAll({s: val})
		//     .then(res => {
		//       //const { count, entries } = res
		//       //this.count = count
		//       console.log(res)
		//       this.entries = res
		//       console.log('then')
		//     })
		//     .catch(err => {
		//       console.log(err)
		//     })
		//     .finally(() => (this.isLoading = false))
		// }
	},
	watch: {
		search (val) {
			// Items have already been loaded
			if (this.items.length > 0) return

			// Items have already been requested
			if (this.isLoading) return

			this.isLoading = true

			// Lazily load input items
			//this.loadFilteredAthletes({s:val})
			api.getAll({s: val})
				.then(res => {
					//const { count, entries } = res
					//this.count = count
					console.log(res)
					this.entries = res
					console.log('then')
				})
				.catch(err => {
					console.log(err)
				})
				.finally(() => (this.isLoading = false))
		},
	},
}
</script>

<style scoped lang="scss">

</style>
