
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiMagnify, mdiAccount } from '@mdi/js';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';
import SharingMetric from "@/components/sharing/SharingMetric.vue";
import RecruitingProfileInfoProvider from "@/components/hoc/RecruitingProfileInfoProvider.vue";
import { RecruitingResponse, RecruitingResponseValues } from '@/../types/enums/recruiting-response';
import { formatDatePrettyLong } from '@/helpers/date';
import { title } from '@/pipes';
import ThumbsDownSvg from '@/components/svg/ThumbsDownSvg.vue';
import MaybeSvg from '@/components/svg/MaybeSvg.vue';
import ThumbsUpSvg from '@/components/svg/ThumbsUpSvg.vue';
import { TargetedSharingModel } from '@/models/targetedSharing/TargetedSharingModel';
import { DataTableHeader } from 'vuetify';
import { DataTableSortMixin } from '@/mixins';
import { RecruitingProfileModel } from '@/models/athlete/RecruitingProfileModel';
import { PageState } from '@/models/PageState';

@Component({
	components: {
		RecruitingProfileInfoProvider,
		SharingMetric,
		ThumbsDownSvg,
		MaybeSvg,
		ThumbsUpSvg,
	}
})
export default class CoachRecruitingTable extends Mixins(DataTableSortMixin, VuetifyMixin){
	formatDatePrettyLong = formatDatePrettyLong;
	mdiMagnify = mdiMagnify;
	mdiAccount = mdiAccount;
	/** Total number of records ie. Profile Shares */
	@Prop({ type: Number, required: true }) value: number;
	/** Label for total number; "Profile Shares", "Profile Views" */
	@Prop({ type: String, required: true }) label: string;
	@Prop({ type: Boolean, default: false }) noDecisionFilter: boolean;
	@Prop({ default: [] }) records: TargetedSharingModel[];
	@Prop({ default: [] }) athleteProfiles: RecruitingProfileModel[];
	@Prop({ type: String, default: "" }) currentTab: string;
	@Prop({ type: String, default: "" }) ready: PageState;
	@Prop({ default: "" }) search: string;
	updateSearch(search: string): void{
		this.$emit('update:search', search);
	}

	headers: DataTableHeader<any>[] = [
		{text: '',value: '',sortable: false,},
		{text: '',value: '',sortable: false,},
		{ text: "Name", value: 'Name', sortable: false },
		{ text: "Age", value: 'age', sortable: false },
		{ text: "Location", value: 'city', sortable: false },
		{ text: "Grad Year", value: 'gradYear', sortable: false },
		{ text: "Primary Position", value: 'PrimaryPosition', sortable: false },
		{ text: "No", value: null, sortable: false },
		{ text: "Maybe", value: null, sortable: false },
		{ text: "Yes", value: null, sortable: false },
		{text: '',value: '',sortable: false,},
	];

	responseFilters: {text: string, value: RecruitingResponse}[] = [];
	updateResponseFilters(responseFilters: {text: string, value: RecruitingResponse}[]): void{
		this.$emit('update:response-filters', responseFilters);
	}
	get Responses(): {text: string, value: RecruitingResponse}[]{
		return [
			{
				text: "None",
				value: null,
			},
			...RecruitingResponseValues.map(r => ({
				text: title(r),
				value: r,
			}))
		];
	}

	isNo(response: RecruitingResponse | null): string{
		if(response === null) return 'baColorGray1';
		if(response === RecruitingResponse.No) return 'baColorRed';
		return 'baColorGray9';
	}
	isMaybe(response: RecruitingResponse | null): string{
		if(response === null) return 'baColorGray1';
		if(response === RecruitingResponse.Maybe) return 'baColorGray2';
		return 'baColorGray9';
	}
	isYes(response: RecruitingResponse | null): string{
		if(response === null) return 'baColorGray1';
		if(response === RecruitingResponse.Yes) return 'baColorGreen1';
		return 'baColorGray9';
	}

	setNo(share: TargetedSharingModel): void{
		this.$emit('click:no', { id: share.id, share });
	}
	setMaybe(share: TargetedSharingModel): void{
		this.$emit('click:maybe', { id: share.id, share });
	}
	setYes(share: TargetedSharingModel): void{
		this.$emit('click:yes', { id: share.id, share });
	}

}
