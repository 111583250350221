
import { Component, Prop, Vue, PropSync } from 'vue-property-decorator';
import { ScoutingReportModel } from '@/models/scoutingReport/ScoutingReportModel';
import { notificationStore } from '@/store';
import { scoutingReportApi } from '@/api/ScoutingReportApi';
import { PaginatedResponse, RepositoryQuery, QueryOptions } from '@/../types/interfaces';
import { PageState } from '@/models/PageState';

export type loadScoutingDataFn = (o: {query?: RepositoryQuery<ScoutingReportModel>, options?: QueryOptions}) => Promise<void>
export type LoadScoutingDataParams = {query?: RepositoryQuery<ScoutingReportModel>, options?: QueryOptions};

@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	},
})
export default class ScoutingReportsInfoProvider extends Vue{
	@Prop({ default: 'div' }) as: string;
	
	async created(): Promise<void> {
		await this.initReports();
	}
	async loadScoutingData(opts:LoadScoutingDataParams = {}): Promise<void> {
		this.scoutingReportsPaginationParams = opts;
		const {query={},options={}} = opts;
		this.scoutingReportsPagination = await scoutingReportApi.queryAllWithAccess(
			query,
			options
		);
	}

	state: PageState = new PageState("Initial");

	@PropSync('query', { default: () => ({}) }) scoutingReportsPaginationParams: LoadScoutingDataParams;
	scoutingReportsPagination: PaginatedResponse<ScoutingReportModel> | null = null;

	async initReports():Promise<void> {
		try{
			this.state = new PageState("Loading");
			await this.loadScoutingData(this.scoutingReportsPaginationParams);
			this.state = new PageState("Ready");
		}catch(error){
			notificationStore.pushErrorNotificationMessage("Failed to load Scouting Reports. Please try again later.");
			this.state = PageState.getPageState(error);
		}
	}

	async deleteReport(report: ScoutingReportModel): Promise<void>{
		await scoutingReportApi.delete(report);
		if(this.scoutingReportsPagination !== null){
			const index = this.scoutingReportsPagination.docs.findIndex(x => x.id === report.id);
			if(index > -1){
				this.scoutingReportsPagination.docs.splice(index, 1);
			}
		}
		this.loadScoutingData(this.scoutingReportsPaginationParams);
	}

	get TotalPages(): number{
		if(this.scoutingReportsPagination === null) return 0;
		return this.scoutingReportsPagination.totalPages;
	}

	get TotalScoutingReports(): number{
		if(this.scoutingReportsPagination === null) return 0;
		return this.scoutingReportsPagination.total;
	}

	get ScoutingReports(): ScoutingReportModel[]{
		if(this.scoutingReportsPagination === null) return [];
		return this.scoutingReportsPagination.docs;
	}

	get SlotProps(): Record<string,any> {
		return {
			state: this.state,
			ScoutingReports: this.ScoutingReports,
			TotalScoutingReports: this.TotalScoutingReports,
			TotalPages: this.TotalPages,
			loadScoutingData: this.loadScoutingData.bind(this),
			deleteReport: (report: ScoutingReportModel) => this.deleteReport(report),
		}
	}
}
