<template>
  <section>
    <v-row align="center">
      <v-col cols="9">
        <v-text-field
          :rules="rulesRequired"
          outlined
          label="Description"
          :hint="hint"
          persistent-hint
          :value="value.description"
          @input="update('description', $event)">
        </v-text-field>
      </v-col>

      <v-col cols="2" class="pb-0 pt-0 mb-0">
        <v-select
          clearable
          :rules="rulesRequiredArray"
          outlined
          v-model="value.year"
          :items="items"
          label="Year"
          multiple
          @change="sortSelection"
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">
              {{ item }} 
            </span>
            <span
              v-if="index === 1"
              class="grey--text caption ml-1"
            >({{value.year.length}})</span>
          </template>
        </v-select>
      </v-col>
      
      <v-col cols="1">
        <v-icon v-if="completed" @click="$emit('removeItem')" color="#81BAED">cancel</v-icon>
      </v-col>
    </v-row>

    <v-divider v-if="numOfItems > 1 && myIndexNum < numOfItems - 1">
    </v-divider>
  </section>
</template>

<script>
export default {
	name: 'OptionalItem',
	props: {
		value: {
			type: Object,
			// default: () => ({}),
		},
		hint: String,
		numOfItems: Number,
		myIndexNum: Number,
		completed: Boolean
	},
	data(){
		return{
			rulesRequired: [
				(v) => !!v || 'Required',
			],
			rulesRequiredArray: [
				(v) => !!(v && v.length) || 'Required'
			]
		}
	},
	computed: {
		items(){
			const listOfYears = []
			let thisYear = new Date()
			thisYear = thisYear.getFullYear()
			let startYear = thisYear - 100
			for (let index = thisYear; index >= startYear; index--) {
				listOfYears.push(index)
			}
			return listOfYears
		},
	},
	methods: {
		sortSelection(){
			if(this.value && this.value.year)
				this.value.year = this.value.year.sort((a, b) => b-a)

			this.update('year', this.value.year)
		},
		updateDate(key, date){
			const dateNum = parseInt(date.substr(0, 4))
			this.value[key] = parseInt(date.substr(0, 4))
		},
		update(key, value) {
			this.$emit('input', {...this.value, [key]: value})
		}
	},
}
</script>

<style lang='scss' scoped>
  .v-text-field.yearDate {
    .v-input__append-inner {
      margin-top: 15px;
    }
  }
</style>