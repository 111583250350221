<template>
  <section>
    <v-form
      v-model="value.valid"
      @submit.prevent.stop=""
    >
      <v-row>
        <v-col cols="12">
          <FormGroupHeading>
            Awards
          </FormGroupHeading>
          <optional-item v-for="(awardItem, index) in value.awards" :key="index"
            v-model="value.awards[index]"
            @input="updateOptionalItem(value.awards[index], $event)"
            :hint="'Add things like Coach of the Year awards'"
            :completed="itemCompleted(value.awards[index])"
            @removeItem="removeItem(value.awards, index)"
          />
          <FormAddArea
            v-if="previousAwardItemCompleted"
            @click="addOptionItem(value.awards)"
            :button-text="noItems(value.awards) ? 'Add award' : 'Add another award'"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <FormGroupHeading>
            Championships
          </FormGroupHeading>
          <optional-item v-for="(championshipItem, index) in value.championships" :key="index"
            v-model="value.championships[index]"
            @input="updateOptionalItem(value.championships[index], $event)"
            :hint="'Add any championship wins that you lead'"
            :completed="itemCompleted(value.championships[index])"
            @removeItem="removeItem(value.championships, index)"
            />
          <FormAddArea
            v-if="previousChampionshipItemCompleted"
            @click="addOptionItem(value.championships)"
            :button-text="noItems(value.championships) ? 'Add championship' : 'Add another championship'"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <FormGroupHeading>
            Successful Players Coached
          </FormGroupHeading>
          <athlete-coached-item v-for="(athleteCoachedItem, index) in value.athletesCoached" :key="index"
            v-model="value.athletesCoached[index]"
            @input="updateOptionalItem(value.athletesCoached[index], $event)"
            :hint="`Add any players that you've coached that have had successful careers`"
            :completed="itemCompleted(value.athletesCoached[index])"
            @removeItem="removeItem(value.athletesCoached, index)"/>
          <FormAddArea
            v-if="previousAthleteCoachedItemCompleted"
            @click="addItem(value.athletesCoached)"
            :button-text="noItems(value.athletesCoached) ? 'Add player' : 'Add another player'"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <FormGroupHeading>
            Certifications
          </FormGroupHeading>
          <certification-item v-for="(certificationItem, index) in value.certifications" :key="index"
            v-model="value.certifications[index]"
            @input="updateOptionalItem(value.certifications[index], $event)"
            :hint="`Add any valid certifications that you hold`"
            :completed="itemCompleted(value.certifications[index])"
            @removeItem="removeItem(value.certifications, index)"/>
          <FormAddArea
            v-if="previousCertificationItemCompleted"
            @click="addItem(value.certifications)"
            :button-text="noItems(value.certifications) ? 'Add certification' : 'Add another certification'"
          />
        </v-col>
      </v-row>
    </v-form>
  </section>
</template>

<script>
import OptionalItem from '@/components/profile/coach/coachResume/OptionalItem'
import AthleteCoachedItem from '@/components/profile/coach/coachResume/AthleteCoachedItem'
import CertificationItem from '@/components/profile/coach/coachResume/CertificationItem'
import FormAddArea from '@/components/forms/FormAddArea'


export default {
	props: {
		value: {
			type: Object,
			default: () => ({}),
		},
	},
	components: {
		OptionalItem,
		AthleteCoachedItem,
		CertificationItem,
	},
	computed:{
		previousAwardItemCompleted(){
			if(!this.value.awards || this.value.awards.length === 0){
				return true
			}
			const length = this.value.awards.length - 1
			if(this.value.awards[length] && this.value.awards[length].description && this.value.awards[length].year && this.value.awards[length].year.length > 0){
				return true
			}
			return false
		},
		previousChampionshipItemCompleted(){
			if(!this.value.championships || this.value.championships.length === 0){
				return true
			}
			const length = this.value.championships.length - 1
			if(this.value.championships[length] && this.value.championships[length].description && this.value.championships[length].year && this.value.championships[length].year.length > 0){
				return true
			}
			return false
		},
		previousAthleteCoachedItemCompleted(){
			if(!this.value.athletesCoached || this.value.athletesCoached.length == 0){
				return true
			}
			const length = this.value.athletesCoached.length - 1
			// Yeah. We can change this formatting, or loop ¯\_(ツ)_/¯
			if(
				this.value.athletesCoached[length]
        && this.value.athletesCoached[length].athleteName
        && this.value.athletesCoached[length].team
        && this.value.athletesCoached[length].year
        && this.value.athletesCoached[length].year.length > 0
			){
				return true
			}
			return false
		},
		previousCertificationItemCompleted(){
			if(!this.value.certifications || this.value.certifications.length == 0){
				return true
			}
			const length = this.value.certifications.length - 1
			return (this.value.certifications[length] && this.value.certifications[length].description) ? true: false
		},
	},
	methods: {
		noItems(list){
			if(!list || list.length === 0){
				return true
			}
		},
		itemCompleted(item){
			if(item){
				return true
			}
			return false
		},
		removeItem(list, index){
			list.splice(index, 1);
		},
		submit() {
			this.$emit('submit')
		},
		addOptionItem(itemList){
			itemList.push({
				description: null,
				year: null
			})
		},
		addItem(itemList){
			itemList.push({})
		},
		updateOptionalItem(item, value) {
			item = value
			this.$emit('input', this.value)
		}
	},
}
</script>

<style>

</style>