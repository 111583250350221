
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { MyTeamsMixin } from '../../mixins';
import TeamMiniCard from '../ui/TeamMiniCard.vue';

@Component({
	components: { TeamMiniCard },
})
export default class TeamList extends Mixins(MyTeamsMixin){
	openTeamDashboard(teamId){
		this.$router.push({ name: 'TeamDashboard', params: { teamId }})
	}
}
